import { Box } from '@mui/material'
import BigNumber from 'bignumber.js'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { useTranslation } from 'contexts/Localization'
import React, { useContext, useMemo, useState } from 'react'
import { StepRequirementEnum } from 'state/types'
import { ClaimableIDO, PurchasableIDO } from 'state/v2_types'
import styled, { useTheme } from 'styled-components'
import { useSaleUserData } from 'state/idos/hooks'
import { convertFromWei } from 'utils/formatBalance'
import { SoldOutDark, SoldOutLight, SoldOutAethir } from 'componentsV2/Icons/Sale/SoldOut'

import SaleDetailCard from '../SaleDetailCard'
import { CompanyContext } from 'contexts/CompanyContext'
import DefaultIFTooltip from 'componentsV2/DefaultIFTooltip'
import { Flex } from 'uikit'

const PurchasedAmountWrapper = styled.div`
  margin: 20px 30px 0;
  padding: 20px;
  border-radius: 8px;
  border: ${({ theme }) => `1px solid ${theme.colorsV2?.light}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 315px;
`

const PromoCodeWrapper = styled.div`
  max-width: 300px;
  margin-top: 5px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const SoldOutCard: React.FC<{ sale: PurchasableIDO; projectImage: string }> = ({ sale, projectImage }) => {
  const userData = useSaleUserData(sale.id)
  const theme = useTheme()
  const { t } = useTranslation()
  const { isJustSale } = useContext(CompanyContext)
  const {
    purchasePeriod: { salePrice, endTime },
    chainId,
    paymentToken,
    saleToken,
    stepRequirement,
  } = sale
  const [isPromoUsed, setPromo] = useState(false)
  const { claimPeriod } = sale as unknown as ClaimableIDO
  const isClaimable = !!claimPeriod?.startTime

  const countdownDate = isClaimable ? claimPeriod.startTime : endTime

  const paymentReceivedInWei = (userData && userData.paymentReceivedInWei) ?? new BigNumber(0)

  const paymentReceivedInPaymentToken = convertFromWei(paymentReceivedInWei).div(
    new BigNumber(10).pow((paymentToken.decimals || 18) - (saleToken.decimals || 18)),
  )
  const purchasedAmount = paymentReceivedInPaymentToken.dividedBy(salePrice)

  const countdownInfo = useMemo(() => {
    const claimTimestamp = new Date(countdownDate).getTime()
    const currentTimestamp = new Date().getTime()
    const seconds = Math.trunc((claimTimestamp - currentTimestamp) / 1000)
    return {
      seconds,
      description: t(isClaimable ? 'Claims in' : 'Purchase closes in'),
    }
  }, [t, countdownDate])

  const imageInfo = useMemo(() => {
    return {
      image: isJustSale ? <SoldOutAethir /> : theme.isDark ? <SoldOutDark /> : <SoldOutLight />,
    }
  }, [theme])

  const isPurchased = paymentReceivedInPaymentToken.isNaN()
    ? userData.amountPurchased.isGreaterThan(0.0001)
    : paymentReceivedInPaymentToken.isGreaterThan(0)
  const title = t(isPurchased ? 'Thank you for your participation.' : 'The sale has ended')

  const subtitleInfo = useMemo(() => {
    return {
      subtitle: t(
        isPurchased
          ? ` ${
              isJustSale
                ? `${sale?.saleTitle} has been sold out`
                : '$Tokens has been sold out. Claim your tokens when the claiming period starts.'
            }`
          : ` ${isJustSale ? `${sale?.saleTitle} has been sold out` : 'Token has been sold out'}`,
      ),
    }
  }, [t])

  const amountPurchased = (userData && userData.amountPurchased.toNumber()) ?? new BigNumber(0)

  const child = useMemo(() => {
    const amount = new BigNumber(purchasedAmount).decimalPlaces(2).toNumber().toLocaleString()

    if (!isPurchased && isJustSale) {
      return null
    }

    if (isJustSale) {
      return (
        <>
          <PurchasedAmountWrapper>
            <Box>
              <img src={'images/whitelabel/moonveil/nft-muse.webp'} alt="token_icon" width="196px" />
            </Box>
            <Flex alignItems="center" style={{ gap: 5 }} marginTop="5px">
              <IFTypography ifcolor="textSecondary" variant="button" fontWeight="regular">
                {t('Total purchased')}
              </IFTypography>

              <IFTypography ifcolor="text" variant="h2" fontWeight="regular">
                {amountPurchased}
              </IFTypography>
              <IFTypography ifcolor="textSecondary" variant="button" fontWeight="regular">
                {t('NFT')}
              </IFTypography>
            </Flex>
          </PurchasedAmountWrapper>
          {isPromoUsed && (
            <PromoCodeWrapper>
              <IFTypography ifcolor="textSecondary" fontSize="12px" align="center">
                Promo code applied! You will receive --amount-- --token-- airdrop by meeting the cashback airdrop
                condition.
              </IFTypography>

              <Flex justifyContent="center" padding="10px 15px">
                <IFTypography variant="caption" ifcolor="textSecondary">{`${t(
                  'Why am I not receiving cashback?',
                )}`}</IFTypography>
                <DefaultIFTooltip
                  color={theme.colorsV2.main4}
                  placement="top"
                  leaveTouchDelay={4000}
                  title="Rebate will only be approved and airdropped by XX upon confirmation referrer has purchased a node."
                />
              </Flex>
            </PromoCodeWrapper>
          )}
        </>
      )
    }
    return (
      <PurchasedAmountWrapper>
        <IFTypography ifcolor="textSecondary" variant="button" fontWeight="regular">
          {isPurchased ? t('Your purchased tokens') : t('Your Total Share')}
        </IFTypography>
        <Box display="flex" alignContent="center">
          <Box marginRight="10px">
            <img src={saleToken.tokenImage || projectImage} alt="token_icon" width="36px" />
          </Box>
          <IFTypography ifcolor="text" variant="h2" fontWeight="regular">
            {amount}
          </IFTypography>
        </Box>
      </PurchasedAmountWrapper>
    )
  }, [saleToken, projectImage, purchasedAmount, t, isPurchased])

  return (
    <SaleDetailCard
      requireKYC={!!stepRequirement?.includes(StepRequirementEnum.KYC)}
      targetNetworkID={chainId}
      imageInfo={imageInfo}
      title={title}
      subtitleInfo={subtitleInfo}
    >
      {child}
    </SaleDetailCard>
  )
}

export default SoldOutCard
