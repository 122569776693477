import React, { useContext } from 'react'
import styled from 'styled-components'
import { Box, Grid } from '@mui/material'
import { Text, useMatchBreakpoints } from 'uikit'
import { useTranslation } from 'contexts/Localization'
import { Brands, CompanyContext } from 'contexts/CompanyContext'
import { TwitterIcon, TelegramIcon, DiscordIcon, RoninExplorerIcon, BlogIcon } from './Icons'

const Pipe = styled.div`
  color: ${({ theme }) => (theme.isColorOverride ? theme.colorsV2.light : theme.common.pipeFooter)};
  opacity: ${({ theme }) => (theme.isDark ? 0.6 : 1)};
`

const FooterWrapper = styled.div<{ isNodeSale: boolean }>`
  padding: 28px 0;
  background: ${({ theme }) => (theme.isColorOverride ? theme.colorsV2.main2 : theme.footer.background)};
  width: 100%;

  @media (max-width: 969px) {
    margin-bottom: ${({ isNodeSale }) => (isNodeSale ? '0' : '70px')};
  }
`

const FooterLink = styled(Text)`
  color: ${({ theme }) => (theme.isColorOverride ? theme.colorsV2.textSecondary : theme.footer.text)};
  white-space: nowrap;
  opacity: ${({ theme }) => (theme.isDark ? 0.6 : 1)};
  font-size: 12px;

  :hover {
    color: ${({ theme }) => theme.colorsV2?.textThirdly};
    opacity: 1;
  }

  @media (max-width: 969px) {
    color: ${({ theme }) => (theme.isColorOverride ? theme.colorsV2.textSecondary : theme.footer.text)};
    opacity: 1;
    font-size: 10px;
  }
`

const Footer: React.FC = () => {
  const { isXl } = useMatchBreakpoints()
  const { companyName, brand, links, footerImage, footerImageMarginLeft, isNodeSale, footerText } =
    useContext(CompanyContext)
  const { t } = useTranslation()
  const isMobile = isXl === false
  return (
    <FooterWrapper isNodeSale={isNodeSale}>
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} position="relative">
        {isMobile && (
          // Render the footerImage first for mobile
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin={brand === Brands.BEAM ? '0 0 20px 15px' : '0 0 20px 0'} // Adjust margin as needed
          >
            {footerImage}
          </Box>
        )}
        {!isMobile && (
          <Box display="flex" alignItems="center" flex={1} paddingLeft="30px">
            {links.explorer && brand === Brands.RONIN && (
              <Box marginLeft="30px" width="18px">
                <a href={links.explorer} target="_blank" rel="noopener noreferrer">
                  <RoninExplorerIcon />
                </a>
              </Box>
            )}
            {links.blog && (
              <Box marginLeft="30px" width="18px">
                <a href={links.blog} target="_blank" rel="noopener noreferrer">
                  <BlogIcon />
                </a>
              </Box>
            )}
            {links.x && (
              <Box marginLeft="30px" width="18px">
                <a href={links.x} target="_blank" rel="noopener noreferrer">
                  <TwitterIcon />
                </a>
              </Box>
            )}
            {links.telegram && (
              <Box marginLeft="30px" width="18px">
                <a href={links.telegram} target="_blank" rel="noopener noreferrer">
                  <TelegramIcon />
                </a>
              </Box>
            )}
            {links.discord && (
              <Box marginLeft="30px" width="18px">
                <a href={links.discord} target="_blank" rel="noopener noreferrer">
                  <DiscordIcon />
                </a>
              </Box>
            )}
          </Box>
        )}
        {!isMobile && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex={1}
            columnGap={4}
            style={{
              top: isNodeSale ? '-23%' : '0',
              left: '44%',
              position: 'absolute',
              marginLeft: footerImageMarginLeft || '-99px',
            }}
          >
            {footerImage}
          </Box>
        )}

        <Grid
          container
          marginTop={brand === Brands.RONIN && isMobile && 10}
          alignItems="center"
          justifyContent={isMobile ? 'center' : 'flex-end'}
          flex={1}
        >
          <Box display="flex" alignItems="center">
            {links.terms && (
              <>
                {!isMobile && <Pipe>|</Pipe>}
                <Box marginX={isMobile ? '15px' : '35px'}>
                  <a href={links.terms} target="_blank" rel="noopener noreferrer">
                    <FooterLink>{t('Terms')}</FooterLink>
                  </a>
                </Box>
              </>
            )}

            {links.privacy && (
              <>
                <Pipe>|</Pipe>
                <Box marginX={isMobile ? '15px' : '35px'}>
                  <a href={links.privacy} target="_blank" rel="noopener noreferrer">
                    <FooterLink>{t('Privacy Policy')}</FooterLink>
                  </a>
                </Box>
              </>
            )}

            {links.cookie && (
              <>
                <Pipe>|</Pipe>
                <Box marginLeft={isMobile ? '15px' : '35px'} marginRight={isMobile ? 0 : 8}>
                  <a href={links.cookie} target="_blank" rel="noopener noreferrer">
                    <FooterLink>{t('Cookie Policy')}</FooterLink>
                  </a>
                </Box>
              </>
            )}

            {!isMobile && (
              <>
                <Pipe>|</Pipe>
                <Box margin="0 60px 0 30px">
                  <FooterLink>{footerText ? footerText : `© 2024 ${companyName}`}</FooterLink>
                </Box>
              </>
            )}
          </Box>
        </Grid>
        {isMobile && (
          <>
            <Box display="flex" justifyContent="center" marginY="10px">
              <a href={links.privacy} target="_blank" rel="noopener noreferrer">
                <FooterLink>{footerText ? footerText : `© 2024 ${companyName}`}</FooterLink>
              </a>
            </Box>
          </>
        )}
      </Box>
    </FooterWrapper>
  )
}

export default Footer
