import { useIFTieredSaleContract } from 'hooks/useContract'
import { isAddress } from 'utils'

export const useFetchPromoCode = (contractAddress: string) => {
  const IFTieredSaleContract = useIFTieredSaleContract(contractAddress)

  const supportsWalletRef = async (tierId: string) => {
    const res = await IFTieredSaleContract.methods.tiers(tierId).call()

    if (!res[7]) {
      return false
    } else {
      return true
    }
  }

  const fetchPromo = async (code: string) => {
    if (isAddress(code)) {
      const res = await IFTieredSaleContract.methods.validateWalletPromoCode(code).call()

      return res
    } else {
      const res = await IFTieredSaleContract.methods.promoCodes(code.toLowerCase()).call()

      if (res.promoCodeOwnerAddress === '0x0000000000000000000000000000000000000000') {
        return false
      }

      return res
    }
  }

  return { fetchPromo, supportsWalletRef }
}
