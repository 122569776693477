import { SubscribeableIDO, IDO, DroppedIDO, PurchasableIDO, FixedAllocationIDO } from 'state/v2_types'
import React, { useContext, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useGetAllocation, useGetMaxPaymentInWeiV2 } from 'hooks/useIFASale'
import { useSaleUserData } from 'state/idos/hooks'
import { convertFromWei } from 'utils/formatBalance'
import { isPurchaseableIDO } from 'state/idos/saleUtil'
import { Box } from '@mui/material'
import styled from 'styled-components'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { useTranslation } from 'contexts/Localization'
import { CompanyContext } from 'contexts/CompanyContext'

interface ProgresBarProps {
  sale: IDO
  isPrivate?: boolean
  totalAllocation?: BigNumber
}

const RemainingTokensSection = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 5px 0px;
  flex: 2;
`
const ProgessBar = styled.div<{ $canPurchase: boolean; isNodeSale?: boolean }>`
  border-radius: 5px;
  display: flex;
  background: ${({ theme, isNodeSale }) =>
    isNodeSale ? theme.colorsV2.light : theme.isDark ? theme.colorsV2?.dark2 : theme.colorsV2?.light};
  border: ${({ theme }) => (theme.isDark ? theme.colorsV2?.light : theme.colorsV2?.textDisabled)};
  height: 7px;
  & > div {
    border-radius: 20px;
    background: ${({ theme, $canPurchase }) =>
      $canPurchase
        ? theme.colorsV2?.textThirdly
        : theme.isDark
        ? theme.colorsV2.textDisabled
        : theme.colorsV2.feedbacks.disableBg};
    height: 7px;
  }
`

const ProgressBar: React.FC<ProgresBarProps> = ({ sale, isPrivate, totalAllocation: totalAllocationPrivate }) => {
  const { t } = useTranslation()
  const { isNodeSale } = useContext(CompanyContext)
  const isPurchaseable = isPurchaseableIDO(sale as PurchasableIDO)
  const { allo: userAllocationInWEI } = useGetAllocation(sale.id, sale.isPrivate)
  const pricePerNode = !isPurchaseable ? 0 : (sale as PurchasableIDO).purchasePeriod.salePrice
  const paymentToken = !isPurchaseable ? null : (sale as PurchasableIDO).paymentToken

  const { purchasePeriod, token: saleToken, saleAddress, saleTokenDefaultName } = sale as PurchasableIDO

  const { publicAllocation, totalPaymentReceived, maxTotalPurchasable, salePrice } = purchasePeriod

  const userAllocationInWei = new BigNumber(publicAllocation).isGreaterThan(0)
    ? publicAllocation.toString()
    : userAllocationInWEI
  const userAllocation = userAllocationInWei && convertFromWei(new BigNumber(userAllocationInWei), saleToken.decimals)
  const userData = useSaleUserData(sale.id)
  const paymentDecimals = paymentToken?.decimals || 0
  const paymentTokenNeeded = convertFromWei(BigNumber(userAllocationInWei), paymentDecimals)
  const totalPurchasedAllocation = new BigNumber(totalPaymentReceived).dividedBy(pricePerNode)
  const canPurchase = paymentDecimals > 0 && paymentTokenNeeded.isGreaterThan(0.001)

  const totalPercentageInt = totalPurchasedAllocation
    .div(new BigNumber(maxTotalPurchasable))
    .multipliedBy(100)
    .isGreaterThan(100)
    ? new BigNumber(100).toNumber()
    : totalPurchasedAllocation.div(new BigNumber(maxTotalPurchasable)).multipliedBy(100).decimalPlaces(2).toNumber()

  const totalAllocation = userAllocation || userData?.userTokenAllocation || new BigNumber(0)

  const totalPercentage = totalAllocation.isEqualTo(0) ? 0 : totalPercentageInt
  const minimumPrgoressBar = 2

  const purchasedAllocation = (userData && userData.amountPurchased) ?? new BigNumber(0)
  const totalPercentageIntPrivate = purchasedAllocation.div(totalAllocationPrivate).multipliedBy(100)?.toNumber() ?? 0

  const barWidthInt =
    totalPercentage < 2 && totalPercentage !== 0
      ? minimumPrgoressBar
      : isPrivate
      ? totalPercentageIntPrivate
      : totalPercentage

  return (
    <Box>
      <RemainingTokensSection>
        {isPrivate ? (
          <>
            <ProgessBar $canPurchase={isNodeSale ? true : canPurchase} isNodeSale={isNodeSale}>
              <Box borderRadius="20px" width={`${barWidthInt}%`} />
            </ProgessBar>
          </>
        ) : (
          <>
            <Box borderRadius="20px">
              <ProgessBar $canPurchase={isNodeSale ? true : canPurchase} isNodeSale={isNodeSale}>
                <Box borderRadius="20px" width={`${barWidthInt}%`} />
              </ProgessBar>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box textAlign="left" marginTop="4px">
                <IFTypography
                  variant="overline"
                  fontFamily="Roboto"
                  ifcolor={canPurchase || isNodeSale ? 'textSecondary' : 'textDisabled'}
                  lineHeight="18px"
                >
                  {}
                  {`${totalPurchasedAllocation.decimalPlaces(2).toNumber().toLocaleString()}/${maxTotalPurchasable} ${
                    saleToken.symbol || saleTokenDefaultName
                  } ${t('sold')}`}
                </IFTypography>
              </Box>
              <Box textAlign="right" marginTop="4px">
                <IFTypography
                  variant="overline"
                  fontFamily="Roboto"
                  ifcolor={canPurchase || isNodeSale ? 'textSecondary' : 'textDisabled'}
                  lineHeight="18px"
                >
                  {maxTotalPurchasable !== 0 &&
                    `${totalPurchasedAllocation
                      .dividedBy(maxTotalPurchasable)
                      .multipliedBy(100)
                      .decimalPlaces(2)
                      .toNumber()
                      .toLocaleString()}%`}
                </IFTypography>
              </Box>
            </Box>
          </>
        )}
      </RemainingTokensSection>
    </Box>
  )
}

export default ProgressBar
